import FirebaseService from 'services/FirebaseService';
const { Offices, UserOffices, UserDeliveryAreas } = FirebaseService;

export async function filterParticipants(participants, user) {
  if (user.type === 'admin') {
    return participants;
  }

  var userOffices = await UserOffices.findByUserId(user.id);
  var officeIds = userOffices.map((o) => o.office_id);

  if (user.type === 'area_manager') {
    var userDeliveryAreas = await UserDeliveryAreas.findByUserId(user.id);
    var officesFromDB = await Offices.findByCompanyId(user.companyId);
    var offices = officesFromDB.filter((o) =>
      userDeliveryAreas.find((d) => o.delivery_area_id === d.delivery_area_id),
    );
    var officesOnlyId = offices.map((o) => o.id);

    officeIds = [...officeIds, ...officesOnlyId];
  }

  const participantsFromUserOffices = participants.filter((p) =>
    officeIds.find((id) => id === p.officeId),
  );
  return participantsFromUserOffices;
}

export function combineParticipantsWithoutDuplicates(
  participants,
  userParticipants,
) {
  var result = [...participants];

  userParticipants.forEach((p) => {
    var participant = result.find((r) => r.id === p.id);
    if (!participant) {
      result.push(p);
    }
  });

  return result;
}

export function addSupervisors(participants, supervisors) {
  return participants.map((p) => {
    var supervisor = supervisors.find((s) => s.id === p.supervisorId);
    p.supervisor = supervisor?.fullName;

    return p;
  });
}
