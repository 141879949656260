import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'jobs';

const Jobs = {};

Jobs.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

Jobs.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

Jobs.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var job = { ...data, id };

    result.push(job);
  });

  return result;
};

Jobs.add = async (job) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), job);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Jobs.update = async ({
  id,
  capacity,
  company_id,
  description,
  employer_id,
  municipality,
  title,
}) => {
  var result = undefined;

  var newJobToSave = {
    capacity,
    company_id,
    description,
    employer_id,
    municipality,
    title,
  };

  const docRef = doc(db, collectionName, id);

  await updateDoc(docRef, newJobToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Jobs.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default Jobs;
