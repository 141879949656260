import {
  collection,
  query,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'periodic_reports';

const PeriodicReports = {};

PeriodicReports.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

PeriodicReports.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var periodicReport = { ...data, id: doc.id };

    result.push(periodicReport);
  });

  return result;
};

PeriodicReports.findCheckedReportsByParticipantId = async (participantId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
    where('checked', '==', true),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var periodicReport = { ...data, id: doc.id };

    result.push(periodicReport);
  });

  return result;
};

PeriodicReports.add = async (periodicReport) => {
  var newPeriodicReport = undefined;

  const docRef = await addDoc(collection(db, collectionName), periodicReport);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    newPeriodicReport = { ...data, id: docSnap.id };
  }

  return newPeriodicReport;
};

PeriodicReports.updateById = async (id, periodicReport) => {
  const periodicReportRef = doc(db, collectionName, id);

  await updateDoc(periodicReportRef, {
    participantId: periodicReport.participant_id,
    month: periodicReport.month,
    year: periodicReport.year,
    checked: periodicReport.checked,
  });
};

PeriodicReports.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default PeriodicReports;
