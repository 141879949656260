import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'survey_links';

export const getAllSurveyLinks = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

export const findSurveyLinkById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;
    var creation_timestamp = data.creation_timestamp.toDate();

    result = { ...data, id, creation_timestamp };
    if (data.expiration_timestamp) {
      var expiration_timestamp = data.expiration_timestamp.toDate();
      result.expiration_timestamp = expiration_timestamp;
    }
  }

  return result;
};

export const findSurveyLinksBySurveyId = async (surveyId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var creation_timestamp = data.creation_timestamp.toDate();
    var surveyLink = {
      ...data,
      id: doc.id,
      creation_timestamp,
    };

    if (data.completion_timestamp) {
      surveyLink.completion_timestamp = data.completion_timestamp.toDate();
    }

    if (surveyLink.survey_id === surveyId) {
      result.push(surveyLink);
    }
  });

  return result;
};

export const addSurveyLink = async (surveyLink) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), surveyLink);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

export const updateSurveyLink = async (surveyLink) => {
  var result = undefined;

  var newSurveyLinkToSave = { ...surveyLink };
  delete newSurveyLinkToSave.id;
  const docRef = doc(db, collectionName, surveyLink.id);

  await updateDoc(docRef, newSurveyLinkToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

export const deleteSurveyLinkById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};
