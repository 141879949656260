import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'cv_employments';

const CvEmployments = {};

CvEmployments.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

CvEmployments.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var employment = { ...data, id };

    result.push(employment);
  });

  return result;
};

CvEmployments.add = async (employment) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), employment);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

CvEmployments.updateById = async (id, employment) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, employment);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    result = { ...docSnap.data(), id: docSnap.id };
  }

  return result;
};

CvEmployments.deleteById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);

  result = { id };

  return result;
};

export default CvEmployments;
