import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import Employer from 'models/firebase/Employer';
import { db } from './init';

const collectionName = 'employers';

const Employers = {};

Employers.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

Employers.findById = async (findId) => {
  var result = undefined;

  const docRef = doc(db, collectionName, findId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

Employers.findByCompanyId = async (companyId) => {
  var results = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var employer = { ...doc.data(), id: doc.id };

    results.push(employer);
  });

  return results;
};

Employers.add = async (employer) => {
  var result = undefined;
  const newEmployer = new Employer(employer);

  const docRef = await addDoc(
    collection(db, collectionName),
    newEmployer.toFirestore(),
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Employers.update = async (employer) => {
  var result = undefined;

  var newEmployerToSave = { ...employer };
  delete newEmployerToSave.id;
  const docRef = doc(db, collectionName, employer.id);

  const updatedEmployer = new Employer(newEmployerToSave);
  await updateDoc(docRef, updatedEmployer.toFirestore());

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Employers.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default Employers;
