import {
  collection,
  query,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'files';

const Files = {};

Files.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

Files.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id };
  }

  return result;
};

Files.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var timestamp = data.timestamp.toDate();
    var file = { ...data, id, timestamp };

    if (file.participant_id === participantId) {
      result.push(file);
    }
  });

  return result;
};

Files.add = async (file) => {
  var newFile = undefined;

  const docRef = await addDoc(collection(db, collectionName), file);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    newFile = { ...data, id: docSnap.id };
  }

  return newFile;
};

Files.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default Files;
