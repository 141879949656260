import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';
import {
  deleteSurveyLinkById,
  findSurveyLinksBySurveyId,
} from './survey_links';
import {
  deleteSurveyResponseById,
  findSurveyResponsesBySurveyId,
} from './survey_responses';

const collectionName = 'questionnaires';

const Questionnaires = {};

Questionnaires.findSurveyByIdAndUser = async (id, user) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;
    var creation_timestamp = data.creation_timestamp.toDate();
    var expiration_timestamp = data.expiration_timestamp.toDate();

    if (data.company_id === user.company_id) {
      result = { ...data, id, creation_timestamp, expiration_timestamp };
    }
  }

  return result;
};

Questionnaires.findSurveyById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;
    var creation_timestamp = data.creation_timestamp.toDate();
    var expiration_timestamp = data.expiration_timestamp.toDate();
    result = { ...data, id, creation_timestamp, expiration_timestamp };
  }

  return result;
};

Questionnaires.findByCompanyId = async (id) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', id),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var survey = {
      ...data,
      id: doc.id,
    };

    result.push(survey);
  });

  return result;
};

Questionnaires.add = async (survey) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), survey);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Questionnaires.updateById = async (id, questionnaire) => {
  var result = undefined;

  var newSurveyToSave = { ...questionnaire };
  delete newSurveyToSave.id;
  const docRef = doc(db, collectionName, id);

  await updateDoc(docRef, newSurveyToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Questionnaires.deleteById = async (id) => {
  var surveyLinks = await findSurveyLinksBySurveyId(id);
  surveyLinks.forEach((link) => deleteSurveyLinkById(link.id));

  var surveyResponses = await findSurveyResponsesBySurveyId(id);
  surveyResponses.forEach((response) => deleteSurveyResponseById(response.id));

  await deleteDoc(doc(db, collectionName, id));
};

export default Questionnaires;
