import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
  onSnapshot,
} from 'firebase/firestore';
import Office from 'models/firebase/Office';
import { db } from './init';

const collectionName = 'predefined_calendars';

const PredefinedSchedules = {};

PredefinedSchedules.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

PredefinedSchedules.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = { ...doc.data(), id: doc.id };
    result.push(data);
  });

  return result;
};

PredefinedSchedules.subscribeByCompanyId = (companyId, callback) => {
  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  return onSnapshot(q, (querySnapshot) => {
    var result = [];
    querySnapshot.forEach((doc) => {
      var data = { ...doc.data(), id: doc.id };
      result.push(data);
    });
    callback(result);
  });
};

PredefinedSchedules.add = async (newCalendar) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), newCalendar);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

PredefinedSchedules.updateById = async (id, newOffice) => {
  var result = undefined;

  const office = new Office(newOffice);

  const docRef = doc(db, collectionName, id);

  await updateDoc(docRef, office.toFirestore());

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

PredefinedSchedules.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default PredefinedSchedules;
