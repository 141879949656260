import {
  collection,
  query,
  where,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import Participant from 'models/firebase/Participant';
import { db } from './init';
import User from 'models/domain/User';

const collectionName = 'participants';

const Participants = {};

Participants.findByCompanyId = async (companyId) => {
  var results = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var document = {
      ...data,
      id: doc.id,
    };

    results.push(document);
  });

  return results;
};

Participants.findByEmail = async (email) => {
  var result = undefined;

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var document = { ...data, id: doc.id };

    if (document.email === email) {
      result = new User(document);
    }
  });

  return result;
};

Participants.subscribeByCompanyId = (companyId, callback) => {
  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    var results = [];
    querySnapshot.forEach((doc) => {
      var data = doc.data();
      var document = {
        ...data,
        id: doc.id,
      };

      results.push(document);
    });

    callback(results);
  });

  return unsubscribe;
};

Participants.findBySocialSecurityNumber = async (socialSecurityNumber) => {
  const q = query(
    collection(db, collectionName),
    where('social_security_number', '==', socialSecurityNumber),
  );

  // return the first document found
  let result = null;

  const querySnapshot = await getDocs(q);

  if (querySnapshot.size > 0) {
    var data = querySnapshot.docs[0].data();
    result = {
      ...data,
      id: querySnapshot.docs[0].id,
    };
  }

  return result;
};

function mapParticipant(participant) {
  var result = {
    /* PARTICIPANT */
    first_name: participant.first_name || '',
    last_name: participant.last_name || '',
    address: participant.address || '',
    postal_code: participant.postal_code,
    postal_address: participant.postal_address || '',
    email: participant.email,
    phone_number: participant.phone_number,
    social_security_number: participant.social_security_number || '',

    /* ESSENSI */
    company_id: participant.company_id,
    supervisor_id: participant.supervisor_id || '',
    started: participant.started || '',
    office_id: participant.office_id || '',

    /* OCCUPATION */
    under_watch_note: participant.under_watch_note || '',
    under_watch: participant.under_watch || '',

    /* INTERNSHIP */
    internship_employer: participant.internship_employer || '',
    internship_start_date: participant.internship_start_date || '',
    internship_end_date: participant.internship_end_date || '',
    internship_note: participant.internship_note || '',
    internship_registered: participant.internship_registered || '',

    /* WORK STUDIES SIGNAL */
    work_studies_signal: participant.work_studies_signal || '',
    work_studies_signal_activity:
      participant.work_studies_signal_activity || '',
    work_studies_signal_employer:
      participant.work_studies_signal_employer || '',
    work_studies_signal_start_date:
      participant.work_studies_signal_start_date || '',
    work_studies_signal_precentage:
      participant.work_studies_signal_precentage || '',
    work_studies_signal_note: participant.work_studies_signal_note || '',
    work_studies_signal_registered:
      participant.work_studies_signal_registered || '',

    /* RESULTAT */
    results_signal: participant.results_signal || '',
    result_1_report_activity: participant.result_1_report_activity || '',
    result_1_report_employer: participant.result_1_report_employer || '',
    result_1_report_date_sent: participant.result_1_report_date_sent || '',
    result_1_report_percentage: participant.result_1_report_percentage || '',
    result_1_report_note: participant.result_1_report_note || '',
    result_1_report_registered: participant.result_1_report_registered || '',
    result_1_report_will_not_be_sent:
      participant.result_1_report_will_not_be_sent || '',
    result_1_report_approved_date:
      participant.result_1_report_approved_date || '',
    result_1_report_approved: participant.result_1_report_approved || '',
    result_1_report_rejected: participant.result_1_report_rejected || '',
    result_1_report_sent_by_another_supplier:
      participant.result_1_report_sent_by_another_supplier || '',
    result_2_report_activity: participant.result_2_report_activity || '',
    result_2_report_employer: participant.result_2_report_employer || '',
    result_2_report_date_sent: participant.result_2_report_date_sent || '',
    result_2_report_percentage: participant.result_2_report_percentage || '',
    result_2_report_note: participant.result_2_report_note || '',
    result_2_report_registered: participant.result_2_report_registered || '',
    result_2_report_will_not_be_sent:
      participant.result_2_report_will_not_be_sent || '',
    result_2_report_approved_date:
      participant.result_2_report_approved_date || '',
    result_2_report_approved: participant.result_2_report_approved || '',
    result_2_report_rejected: participant.result_2_report_rejected || '',

    /* FINAL REPORT */
    final_report_next_step: participant.final_report_next_step || '',
    final_report_category: participant.final_report_category || '',
    final_report_note: participant.final_report_note || '',
    final_report_approved: participant.final_report_approved || '',
    final_report_attested: participant.final_report_attested || '',
    final_report_registered: participant.final_report_registered || '',
    final_report_registered_date:
      participant.final_report_registered_date || '',

    predefined_schedule_id: participant.predefined_schedule_id || '',

    /* CASE */
    /* reference_number: participant.reference_number,
    participation_rate: participant.participation_rate || '',
    start_date: participant.start_date || '',
    end_date: participant.end_date || '',
    interruption: participant.interruption || '',
    extension: participant.extension || '',
    level: participant.level || '',
    language_support: participant.language_support || '',
    interpretation_need: participant.interpretation_need || '',
    collaborative_planning_note: participant.collaborative_planning_note || '',
    collaborative_planning_registered:
      participant.collaborative_planning_registered || '', */
  };

  // remove all undefined fields
  for (const key in result) {
    if (
      (key !== 'social_security_number' || key !== 'company_id') &&
      result[key] === undefined
    ) {
      delete result[key];
    }
  }

  return result;
}

Participants.add = async (participant) => {
  const newData = mapParticipant(participant);

  // check if the participant already exists by social_security_number and company_id
  const q = query(
    collection(db, collectionName),
    where('social_security_number', '==', participant.social_security_number),
    where('company_id', '==', participant.company_id),
  );

  const querySnapshot = await getDocs(q);
  if (querySnapshot.size > 0) {
    throw new Error('Participant already exists');
  }

  // Add a new document with a generated id.
  const docRef = await addDoc(collection(db, collectionName), newData);
  return docRef;
};

const validFields = [
  'first_name',
  'last_name',
  'address',
  'postal_code',
  'postal_address',
  'email',
  'phone_number',
  'social_security_number',

  /* ESSENSI */
  'company_id',
  'supervisor_id',
  'started',
  'office_id',

  /* OCCUPATION */
  'under_watch_note',
  'under_watch',

  /* COLLABORATIVE PLANNING */
  'collaborative_planning_registered',
  'collaborative_planning_period_2_registered',
  'collaborative_planning_note',

  /* INTERNSHIP */
  'internship_employer',
  'internship_start_date',
  'internship_end_date',
  'internship_note',
  'internship_registered',

  /* WORK STUDIES SIGNAL */
  'work_studies_signal',
  'work_studies_signal_activity',
  'work_studies_signal_employer',
  'work_studies_signal_start_date',
  'work_studies_signal_precentage',
  'work_studies_signal_note',
  'work_studies_signal_registered',

  /* RESULTAT */
  'results_signal',
  'result_1_report_activity',
  'result_1_report_employer',
  'result_1_report_date_sent',
  'result_1_report_percentage',
  'result_1_report_note',
  'result_1_report_registered',
  'result_1_report_will_not_be_sent',
  'result_1_report_approved_date',
  'result_1_report_approved',
  'result_1_report_rejected',
  'result_1_report_sent_by_another_supplier',
  'result_2_report_activity',
  'result_2_report_employer',
  'result_2_report_date_sent',
  'result_2_report_percentage',
  'result_2_report_note',
  'result_2_report_registered',
  'result_2_report_will_not_be_sent',
  'result_2_report_approved_date',
  'result_2_report_approved',
  'result_2_report_rejected',

  /* FINAL REPORT */
  'final_report_next_step',
  'final_report_category',
  'final_report_note',
  'final_report_approved',
  'final_report_attested',
  'final_report_registered',
  'final_report_registered_date',
  'predefined_schedule_id',
];

function getValidFieldsToUpdate(participant) {
  var result = {};

  for (const key in participant) {
    if (participant[key] !== undefined && validFields.includes(key)) {
      result[key] = participant[key];
    }
  }

  return result;
}

Participants.updateById = async (id, participant) => {
  const participantRef = doc(db, collectionName, id);
  const newData = getValidFieldsToUpdate(participant);
  return await updateDoc(participantRef, newData);
};

Participants.updateDecision = async (participantId, decision) => {
  const participantRef = doc(db, collectionName, participantId);
  let savedData = await updateDoc(participantRef, decision);
  return savedData;
};

Participants.deleteById = async (id) => {
  const participantRef = doc(db, collectionName, id);

  // Delete calendar events by participants id
  // Delete all files by participant id
  // Delete all notes by participant id
  // Delete all participant educations by participant id
  // Delete all participant experiences by participant id
  // Delete all participant languages by participant id
  // Delete all participant licenses by participant id
  // Delete all participant municipalities by participant id
  // Delete all periodic reports by participant id
  // Delete all survey responses by participant id
  // Delete all user questionnaires by participant id

  await deleteDoc(participantRef);
};

export default Participants;
