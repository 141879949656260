import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'job_educations';

const JobEducations = {};

JobEducations.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

JobEducations.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

JobEducations.findByJobId = async (jobId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantEducation = { ...data, id };

    if (participantEducation.job_id === jobId) {
      result.push(participantEducation);
    }
  });

  return result;
};

JobEducations.add = async (participantEducation) => {
  var result = undefined;

  const docRef = await addDoc(
    collection(db, collectionName),
    participantEducation,
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

JobEducations.update = async (participantEducation) => {
  var result = undefined;

  var newJobEducationToSave = { ...participantEducation };
  delete newJobEducationToSave.id;
  const docRef = doc(db, collectionName, participantEducation.id);

  await updateDoc(docRef, newJobEducationToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

JobEducations.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default JobEducations;
