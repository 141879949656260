import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { useSearchParams } from 'react-router-dom/dist';

const PublicRoute = () => {
  const { user } = useSelector((state) => state.auth);

  let [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  return user ? <Navigate to={redirect || AUTHENTICATED_ENTRY} /> : <Outlet />;
};

export default PublicRoute;
