import Auth from './auth';
import Participants from './participants';
import ParticipantLicenses from './participant_licenses';
import ParticipantLanguages from './participant_languages';
import ParticipantExperiences from './participant_experiences';
import ParticipantEducations from './participant_educations';
import ParticipantMunicipalities from './participant_municipalities';
import Employers from './employers';
import Jobs from './jobs';
import JobExperiences from './job_experiences';
import JobEducations from './job_educations';
import JobLicenses from './job_licenses';
import JobLanguages from './job_languages';
import Companies from './companies';
import Offices from './offices';
import PeriodicReports from './periodic_reports';
import Notes from './notes';
import Storage from './storage';
import Files from './files';
import CalendarEventDescriptions from './calendar_event_descriptions';
import CalendarEvents from './calendar_events';
import Coaches from './coaches';
import DeliveryAreas from './delivery_area';
import Questionnaires from './questionnaires';
import Users from './users';
import UserOffices from './user_offices';
import UserDeliveryAreas from './user_delivery_areas';
import PredefinedSchedules from './predefined_schedules';
import PredefinedScheduleEvents from './predefined_schedule_events';
import ScheduleOffices from './schedule_offices';
import CvEducations from './cv_educations';
import CvEmployments from './cv_employments';
import Decisions from './decisions';

const FirebaseService = {
  Auth,
  Participants,
  ParticipantLicenses,
  ParticipantLanguages,
  ParticipantExperiences,
  ParticipantEducations,
  ParticipantMunicipalities,
  Employers,
  Jobs,
  JobExperiences,
  JobEducations,
  JobLicenses,
  JobLanguages,
  Companies,
  Offices,
  PeriodicReports,
  Notes,
  Storage,
  Files,
  CalendarEventDescriptions,
  CalendarEvents,
  Coaches,
  DeliveryAreas,
  Questionnaires,
  Users,
  UserOffices,
  UserDeliveryAreas,
  PredefinedSchedules,
  PredefinedScheduleEvents,
  ScheduleOffices,
  CvEducations,
  CvEmployments,
  Decisions,
};

export default FirebaseService;
