import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'participant_languages';

const ParticipantLanguages = {};

ParticipantLanguages.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

ParticipantLanguages.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

ParticipantLanguages.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantLanguage = { ...data, id };

    if (participantLanguage.participant_id === participantId) {
      result.push(participantLanguage);
    }
  });

  return result;
};

ParticipantLanguages.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantLanguage = { ...data, id };

    result.push(participantLanguage);
  });

  return result;
};

ParticipantLanguages.add = async (participantLanguage) => {
  var result = undefined;

  const docRef = await addDoc(
    collection(db, collectionName),
    participantLanguage,
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantLanguages.update = async (participantLanguage) => {
  var result = undefined;

  var newParticipantLanguageToSave = { ...participantLanguage };
  delete newParticipantLanguageToSave.id;
  const docRef = doc(db, collectionName, participantLanguage.id);

  await updateDoc(docRef, newParticipantLanguageToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantLanguages.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default ParticipantLanguages;
