import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'participant_experiences';

const ParticipantExperiences = {};

ParticipantExperiences.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

ParticipantExperiences.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

ParticipantExperiences.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantExperience = { ...data, id };

    if (participantExperience.participant_id === participantId) {
      result.push(participantExperience);
    }
  });

  return result;
};

ParticipantExperiences.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantExperience = { ...data, id };

    result.push(participantExperience);
  });

  return result;
};

ParticipantExperiences.add = async (participantExperience) => {
  var result = undefined;

  const docRef = await addDoc(
    collection(db, collectionName),
    participantExperience,
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantExperiences.update = async (participantExperience) => {
  var result = undefined;

  var newParticipantExperienceToSave = { ...participantExperience };
  delete newParticipantExperienceToSave.id;
  const docRef = doc(db, collectionName, participantExperience.id);

  await updateDoc(docRef, newParticipantExperienceToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantExperiences.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default ParticipantExperiences;
