import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'cv_educations';

const CvEducations = {};

CvEducations.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

CvEducations.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var education = { ...data, id };

    result.push(education);
  });

  return result;
};

CvEducations.add = async (education) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), education);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

CvEducations.updateById = async (id, education) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, education);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    result = { ...docSnap.data(), id: docSnap.id };
  }

  return result;
};

CvEducations.deleteById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);

  result = { id };

  return result;
};

export default CvEducations;
