import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'participant_educations';

const ParticipantEducations = {};

ParticipantEducations.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

ParticipantEducations.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantEducations.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantEducation = { ...data, id };

    if (participantEducation.participant_id === participantId) {
      result.push(participantEducation);
    }
  });

  return result;
};

ParticipantEducations.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantEducation = { ...data, id };

    result.push(participantEducation);
  });

  return result;
};

ParticipantEducations.add = async (participantEducation) => {
  var result = undefined;

  const docRef = await addDoc(
    collection(db, collectionName),
    participantEducation,
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantEducations.update = async (participantEducation) => {
  var result = undefined;

  var newParticipantEducationToSave = { ...participantEducation };
  delete newParticipantEducationToSave.id;
  const docRef = doc(db, collectionName, participantEducation.id);

  await updateDoc(docRef, newParticipantEducationToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantEducations.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default ParticipantEducations;
