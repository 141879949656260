import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'job_licenses';

const JobLicenses = {};

JobLicenses.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

JobLicenses.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();

    result = { ...data, id: docSnap.id };
  }

  return result;
};

JobLicenses.findByJobId = async (jobId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantLicense = { ...data, id };

    if (participantLicense.job_id === jobId) {
      result.push(participantLicense);
    }
  });

  return result;
};

JobLicenses.add = async (participantLicense) => {
  var result = undefined;

  const docRef = await addDoc(
    collection(db, collectionName),
    participantLicense,
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

JobLicenses.update = async (participantLicense) => {
  var result = undefined;

  var newJobLicenseToSave = { ...participantLicense };
  delete newJobLicenseToSave.id;
  const docRef = doc(db, collectionName, participantLicense.id);

  await updateDoc(docRef, newJobLicenseToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

JobLicenses.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default JobLicenses;
