import FirebaseService from 'services/FirebaseService';
import Base from './Base';
import {
  getMissingReportsForParticipant,
  getMonthsBetweenDates,
  getUniqueReports,
  reportIsInPeriod,
} from 'utils/periodic-reports';
import dateIsValid from 'utils/dates/dateIsValid';

class Participant extends Base {
  constructor(firestoreDocument) {
    super(firestoreDocument);
    this.fullName = this.firstName + ' ' + this.lastName;
  }

  async init() {
    this.periodicReports = await this.#getPeriodicReports();
    this.status = await this.#getStatus();
  }

  async #getStatus() {
    const missingReports = getMissingReportsForParticipant(
      this.periodicReports,
    );

    if (this.#todayisEqualOrLater(this.startDate) && this.started !== 'yes') {
      return 'Ej startad';
    } else if (this.finalReportRegistered && !this.finalReportApproved) {
      return 'Inväntar godkännande av slutrapport';
    } else if (this.finalReportRegistered && !this.finalReportAttested) {
      return 'Inväntar slutattestering';
    } else if (missingReports.length > 0) {
      return 'Inväntar periodisk rapportering';
    } else if (this.finalReportRegistered) {
      return 'Avslutad';
    } else if (this.interruption && this.interruption !== '') {
      return 'Avbrott';
    } else if (
      this.#todayisEqualOrLater(this.startDate) &&
      !this.finalReportRegistered
    ) {
      return 'Aktiv';
    }

    return 'Inskriven';
  }

  async #getPeriodicReports() {
    const startDate = new Date(this.startDate);
    const interruptionDate = new Date(this.interruption);
    let endDate = new Date(this.endDate);

    if (dateIsValid(interruptionDate)) {
      endDate = interruptionDate;
    }

    const existingReports =
      await FirebaseService.PeriodicReports.findCheckedReportsByParticipantId(
        this.id,
      );
    const reportsInPeriod = getMonthsBetweenDates(startDate, endDate);

    const filtered = [...existingReports, ...reportsInPeriod]
      .filter((obj) => reportIsInPeriod(obj, startDate, endDate))
      .map((obj) => ({
        ...obj,
        fullName: this.fullName,
        referenceNumber: this.referenceNumber,
        date: new Date(obj.year, obj.month),
        participant_id: this.id,
      }));

    const periodicReports = getUniqueReports(filtered);
    return periodicReports;
  }

  #todayisEqualOrLater(dateStr) {
    const date = new Date(dateStr);
    const today = new Date();

    return today.getTime() >= date.getTime();
  }
}

export default Participant;
