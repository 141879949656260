import User from 'models/firebase/User';
import {
  collection,
  query,
  getDocs,
  where,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'users';

const Users = {};

Users.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

Users.findByEmail = async (email) => {
  var result = undefined;

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var document = { ...data, id: doc.id };

    if (document.email === email) {
      result = new User(document);
    }
  });

  return result;
};

Users.updateById = async (id, user) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, user);

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Users.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var document = { ...data, id: doc.id };

    result.push(document);
  });

  return result;
};

Users.add = async (user) => {
  const existingUser = await Users.findByEmail(user.email);
  if (existingUser) {
    throw new Error('User already exists');
  }
  const docRef = await addDoc(collection(db, collectionName), user);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    return { ...data, id: docSnap.id };
  }

  return undefined;
};

Users.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default Users;
