import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'participant_licenses';

const ParticipantLicenses = {};

ParticipantLicenses.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

ParticipantLicenses.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

ParticipantLicenses.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantLicense = { ...data, id };

    if (participantLicense.participant_id === participantId) {
      result.push(participantLicense);
    }
  });

  return result;
};

ParticipantLicenses.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantLicense = { ...data, id };

    result.push(participantLicense);
  });

  return result;
};

ParticipantLicenses.add = async (participantLicense) => {
  var result = undefined;

  const docRef = await addDoc(
    collection(db, collectionName),
    participantLicense,
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantLicenses.update = async (participantLicense) => {
  var result = undefined;

  var newParticipantLicenseToSave = { ...participantLicense };
  delete newParticipantLicenseToSave.id;
  const docRef = doc(db, collectionName, participantLicense.id);

  await updateDoc(docRef, newParticipantLicenseToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ParticipantLicenses.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default ParticipantLicenses;
