import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import Office from 'models/firebase/Office';
import { db } from './init';

const collectionName = 'user_offices';

const UserOffices = {};

UserOffices.findByUserId = async (userId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('user_id', '==', userId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var user_office = { ...data, id: doc.id };

    result.push(user_office);
  });

  return result;
};

UserOffices.add = async (userOffice) => {
  var result = undefined;
  const newUserOffice = new Office(userOffice);

  const docRef = await addDoc(
    collection(db, collectionName),
    newUserOffice.toFirestore(),
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

UserOffices.updateById = async (id, newOffice) => {
  var result = undefined;

  const office = new Office(newOffice);

  const docRef = doc(db, collectionName, id);

  await updateDoc(docRef, office.toFirestore());

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

UserOffices.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default UserOffices;
