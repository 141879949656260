import {
  collection,
  query,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'notes';

const Notes = {};

Notes.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

Notes.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var note = { ...data, id: doc.id };
    note.timestamp = note.timestamp?.toDate();

    if (note.participant === participantId) {
      result.push(note);
    }
  });

  return result;
};

Notes.add = async (note) => {
  var newNote = undefined;

  const docRef = await addDoc(collection(db, collectionName), note);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    newNote = { ...data, id: docSnap.id };
    newNote.timestamp = newNote.timestamp?.toDate();
  }

  return newNote;
};

Notes.updateById = async (id, note) => {
  const noteRef = doc(db, collectionName, id);

  await updateDoc(noteRef, {
    participant: note.participant,
    title: note.title,
    text: note.text,
    timestamp: note.timestamp,
    author: note.author,
  });
};

Notes.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default Notes;
