import {
  collection,
  query,
  where,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  getDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'decisions';

const Decisions = {};

Decisions.findByReferenceNumber = async (referenceNumber) => {
  const q = query(
    collection(db, collectionName),
    where('reference_number', '==', referenceNumber),
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  var data = querySnapshot.docs[0].data();
  var document = {
    ...data,
    id: querySnapshot.docs[0].id,
  };

  return document;
};

Decisions.findByCompanyId = async (companyId) => {
  var results = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var document = {
      ...data,
      id: doc.id,
    };

    results.push(document);
  });

  return results;
};

Decisions.findByParticipantId = async (participantId) => {
  var results = [];

  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var document = {
      ...data,
      id: doc.id,
    };

    results.push(document);
  });

  return results;
};

Decisions.subscribeByParticipantId = (participantId, callback) => {
  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    var results = [];
    querySnapshot.forEach((doc) => {
      var data = doc.data();
      var document = {
        ...data,
        id: doc.id,
      };

      results.push(document);
    });

    callback(results);
  });

  return unsubscribe;
};

Decisions.subscribeByCompanyId = (companyId, callback) => {
  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    var results = [];
    querySnapshot.forEach((doc) => {
      var data = doc.data();
      var document = {
        ...data,
        id: doc.id,
      };

      results.push(document);
    });

    callback(results);
  });

  return unsubscribe;
};

Decisions.findById = async (id) => {
  const decisionRef = doc(db, collectionName, id);
  const docSnap = await getDoc(decisionRef);

  if (docSnap.exists()) {
    return {
      ...docSnap.data(),
      id: docSnap.id,
    };
  }

  return null;
};

Decisions.add = async (decision) => {
  const q = query(
    collection(db, collectionName),
    where('reference_number', '==', decision.reference_number),
    where('company_id', '==', decision.company_id),
  );

  const querySnapshot = await getDocs(q);

  let docRef = null;

  if (querySnapshot.empty) {
    // No documents with the same reference number exist, so you can create a new one
    docRef = await addDoc(collection(db, collectionName), decision);
  } else {
    // Throw an error
    throw new Error('A decision with the same reference number already exists');
  }

  return docRef;
};

Decisions.updateById = async (id, decision) => {
  const decisionRef = doc(db, collectionName, id);
  const docSnapshot = await getDoc(decisionRef);

  if (
    docSnapshot.exists() &&
    docSnapshot.data().reference_number !== decision.reference_number
  ) {
    const q = query(
      collection(db, collectionName),
      where('reference_number', '==', decision.reference_number),
      where('company_id', '==', decision.company_id),
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Get the first document in the querySnapshot
      // Update the document
      throw new Error(
        'A decision with the same reference number already exists',
      );
    } else {
      // Throw an error
      await updateDoc(decisionRef, decision);
    }
  } else if (docSnapshot.exists()) {
    await updateDoc(decisionRef, decision);
  }
};

Decisions.addOrUpdate = async (decision) => {
  const q = query(
    collection(db, collectionName),
    where('reference_number', '==', decision.reference_number),
    where('company_id', '==', decision.company_id),
  );

  const querySnapshot = await getDocs(q);

  let docRef = null;

  if (querySnapshot.empty) {
    // No documents with the same reference number exist, so you can create a new one
    docRef = await addDoc(collection(db, collectionName), decision);
  } else {
    const decisionRef = doc(db, collectionName, querySnapshot.docs[0].id);
    docRef = await updateDoc(decisionRef, decision);
  }

  return docRef;
};

Decisions.updateByReferenceNumber = async (referanceNumber, decision) => {
  var document = await Decisions.findByReferenceNumber(referanceNumber);

  if (document) {
    const decisionRef = doc(db, collectionName, document.id);

    await updateDoc(decisionRef, decision);
  }
};

Decisions.deleteById = async (id) => {
  const decisionRef = doc(db, collectionName, id);

  await deleteDoc(decisionRef);
};

export default Decisions;
