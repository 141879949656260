import {
  collection,
  query,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'calendar_event_descriptions';
const CalendarEventDescriptions = {};

CalendarEventDescriptions.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

CalendarEventDescriptions.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var description = { ...data, id: doc.id };

    result.push(description);
  });

  return result;
};

CalendarEventDescriptions.add = async (description) => {
  var newDescription = undefined;
  const docRef = await addDoc(collection(db, collectionName), description);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    newDescription = { ...data, id: docSnap.id };
  }

  return newDescription;
};

CalendarEventDescriptions.updateById = async (id, description) => {
  const descriptionRef = doc(db, collectionName, id);

  await updateDoc(descriptionRef, {
    companyId: description.companyId,
    descriptions: description.descriptions,
    title: description.title,
  });

  var updatedDescription = undefined;

  const docSnap = await getDoc(descriptionRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    updatedDescription = { ...data, id: docSnap.id };
  }

  return updatedDescription;
};

CalendarEventDescriptions.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default CalendarEventDescriptions;
