import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FirebaseService from 'services/FirebaseService';
import { getTimeLeft } from './helper';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  user: null,
  loginStep: 'emailPassword',
  sessionToken: null,
};

const MAX_SESSION_TIME_IN_HOURS = 3;

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (data, { rejectWithValue }) => {
    try {
      const user = await FirebaseService.Auth.getCurrentUser();

      if (user) {
        var timeLeft = getTimeLeft(
          user.metadata.lastLoginAt,
          MAX_SESSION_TIME_IN_HOURS,
        );
        const timeHasPassed = timeLeft <= 0;

        if (timeHasPassed) {
          await FirebaseService.Auth.signOut();
          return null;
        }
      }

      return user;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const setSessionToken = createAsyncThunk(
  'auth/setSessionToken',
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const setLoginStep = createAsyncThunk(
  'auth/setLoginStep',
  async (data, { rejectWithValue, getState }) => {
    try {
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signInWithCustomToken = createAsyncThunk(
  'auth/signInWithCustomToken',
  async (token, { dispatch, rejectWithValue }) => {
    try {
      const user = await FirebaseService.Auth.signInWithCustomToken(token);
      dispatch(setLoginStep('emailPassword'));
      return user;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signIn = createAsyncThunk(
  'auth/login',
  async (data, { dispatch, rejectWithValue }) => {
    const { email, password } = data;
    try {
      let result = null;
      const urlConf = process.env.REACT_APP_FUNCTIONS_BASE_URL;
      const url =
        (urlConf || 'https://essensi-dev-api.firebaseapp.com') + '/auth/login';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.userType !== 'participant') {
        dispatch(setSessionToken(data.token));

        if (data.mfaSetup) {
          dispatch(setLoginStep('otp'));
        } else {
          dispatch(setLoginStep('mfaSetup'));
        }
      } else {
        result = await FirebaseService.Auth.signInWithCustomToken(data.token);
      }

      return result;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signUp = createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue }) => {
    return null;
  },
);

export const signOut = createAsyncThunk('auth/logout', async () => {
  const response = await FirebaseService.Auth.signOut();
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = '/';
      state.user = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = '';
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.user = null;
      state.redirect = '/';
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithCustomToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithCustomToken.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.user = action.payload;
      })
      .addCase(signInWithCustomToken.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.user = action.payload;
      })
      .addCase(setLoginStep.pending, (state) => {
        state.loading = true;
      })
      .addCase(setLoginStep.fulfilled, (state, action) => {
        state.loading = false;
        state.loginStep = action.payload;
      })
      .addCase(setSessionToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(setSessionToken.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionToken = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.redirect = '/';
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.user = null;
        state.redirect = '/';
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
