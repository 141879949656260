import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getBlob,
  deleteObject,
} from 'firebase/storage';
import { v4 as uuid } from 'uuid';
import { storage } from './init';

const Storage = {};

Storage.upload = (file, successCallback, errorCallback) => {
  var path = `files/${uuid()}-${file.name}`;
  const storageRef = ref(storage, path);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      switch (snapshot.state) {
        case 'paused':
          break;
        case 'running':
          break;
      }
    },
    (error) => {
      // Handle unsuccessful uploads
    },
    async () => {
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      successCallback(downloadURL);
    },
  );
};

const downloadBlob = (blob, fileName) => {
  // Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', '_blank');
  // link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

const openBlob = (blobData) => {
  var blobUrl = URL.createObjectURL(blobData);
  window.open(blobUrl);
};

Storage.download = async (url, fileName) => {
  const reference = ref(storage, url);
  var blobData = await getBlob(reference);
  openBlob(blobData);
  // downloadBlob(blobData, fileName);
};

Storage.delete = async (url) => {
  const reference = ref(storage, url);

  deleteObject(reference)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
};

export default Storage;
