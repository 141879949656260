import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FirebaseService from 'services/FirebaseService';
import Participant from 'models/firebase/Participant';
import store from '../../index';
import { fetch as fetchSupervisors } from '../supervisorsSlice';
import {
  addSupervisors,
  combineParticipantsWithoutDuplicates,
  filterParticipants,
} from './helpers';
const { Participants } = FirebaseService;

export const initialState = {
  status: 'not-fetched',
  participants: [],
  userParticipants: [],
};

export const fetch = createAsyncThunk(
  'participants/fetch',
  async (data, { dispatch, rejectWithValue, getState }) => {
    try {
      const user = getState().auth.user;
      const participantsFromDB = await Participants.findByCompanyId(
        user.companyId,
      );

      const participants = await Promise.all(
        participantsFromDB.map(async (p) => {
          const participant = new Participant(p);
          await participant.init();

          return participant;
        }),
      );

      // sort participants by fullName
      participants.sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1;
        }
        if (a.fullName > b.fullName) {
          return 1;
        }
        return 0;
      });

      dispatch(updateParticipants(participants));
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const subscribeToParticipants = createAsyncThunk(
  'participants/subscribe',
  async (_, { dispatch, getState }) => {
    const user = getState().auth.user;
    return Participants.subscribeByCompanyId(user.companyId, async (data) => {
      const participants = await Promise.all(
        data.map(async (p) => {
          const participant = new Participant(p);
          await participant.init();

          return participant;
        }),
      );
      dispatch(updateParticipants(participants));
    });
  },
);

const updateParticipants = createAsyncThunk(
  'participants/updateParticipants',
  async (data, { rejectWithValue, getState }) => {
    try {
      const user = getState().auth.user;
      var supervisors = getState().supervisors.supervisors;
      if (supervisors.length <= 0) {
        supervisors = (await store.dispatch(fetchSupervisors())).payload;
      }

      const participantsFromDB = data;

      var userParticipants = participantsFromDB.filter(
        (p) => p.supervisorId === user.id,
      );
      userParticipants = addSupervisors(userParticipants, supervisors);

      var filteredParticipants = await filterParticipants(
        participantsFromDB,
        user,
      );
      filteredParticipants = addSupervisors(filteredParticipants, supervisors);

      var participants = combineParticipantsWithoutDuplicates(
        filteredParticipants,
        userParticipants,
      );

      return { participants, userParticipants };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const updateParticipant = createAsyncThunk(
  'participants/updateParticipant',
  async (data, { rejectWithValue, getState }) => {
    try {
      var newParticipant = new Participant(data);
      var newDocument = newParticipant.toFirestore();
      const updatedDocument = await Participants.updateById(
        newParticipant.id,
        newDocument,
      );
      const updatedParticipant = new Participant(updatedDocument);
      await updatedParticipant.init();

      return updatedParticipant;
    } catch (err) {
      return rejectWithValue(err || 'Error');
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateParticipants.fulfilled, (state, action) => {
        state.participants = action.payload.participants;
        state.userParticipants = action.payload.userParticipants;
        state.status = 'fetched';
      })
      .addCase(updateParticipant.fulfilled, (state, action) => {
        const participantsCopy = [...state.participants];
        const updatedParticipant = action.payload;
        const index = participantsCopy.findIndex(
          (p) => p.id === updateParticipant.id,
        );
        participantsCopy[index] = updatedParticipant;
        state.participants = participantsCopy;
      })
      .addCase(updateParticipant.rejected, (state, action) => {});
  },
});

export const { fetched } = authSlice.actions;

export default authSlice.reducer;
