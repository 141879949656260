import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import Office from 'models/firebase/Office';
import { db } from './init';

const collectionName = 'offices';

const Offices = {};

Offices.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

Offices.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var office = { ...data, id: doc.id };

    if (data.timestamp) {
      var timestamp = data.timestamp.toDate();
      office.timestamp = timestamp;
    }

    result.push(office);
  });

  return result;
};

Offices.add = async (office) => {
  var result = undefined;
  const newOffice = new Office(office);

  const docRef = await addDoc(
    collection(db, collectionName),
    newOffice.toFirestore(),
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Offices.updateById = async (id, newOffice) => {
  var result = undefined;

  const office = new Office(newOffice);

  const docRef = doc(db, collectionName, id);

  await updateDoc(docRef, office.toFirestore());

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Offices.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default Offices;
