import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
  onSnapshot,
} from 'firebase/firestore';
import Office from 'models/firebase/Office';
import { db } from './init';

const collectionName = 'schedule_offices';

const ScheduleOffices = {};

ScheduleOffices.findByScheduleId = async (scheduleId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('schedule_id', '==', scheduleId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = { data: doc.data(), id: doc.id };

    result.push(data);
  });

  return result;
};

ScheduleOffices.subscribeByCompanyId = (companyId, callback) => {
  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  return onSnapshot(q, (querySnapshot) => {
    var result = [];
    querySnapshot.forEach((doc) => {
      var data = { ...doc.data(), id: doc.id };
      result.push(data);
    });
    callback(result);
  });
};

ScheduleOffices.add = async (scheduleOffice) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), scheduleOffice);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ScheduleOffices.updateById = async (id, newOffice) => {
  var result = undefined;

  const office = new Office(newOffice);

  const docRef = doc(db, collectionName, id);

  await updateDoc(docRef, office.toFirestore());

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

ScheduleOffices.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

ScheduleOffices.deleteByScheduleId = async (scheduleId) => {
  var result = await ScheduleOffices.findByScheduleId(scheduleId);
  result?.forEach((co) => {
    ScheduleOffices.deleteById(co.id);
  });
};

export default ScheduleOffices;
