import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'survey_responses';

export const getAllSurveyResponses = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

export const findSurveyResponseById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var creation_timestamp = data.creation_timestamp.toDate();

    result = {
      ...data,
      id: doc.id,
      creation_timestamp,
    };
  }

  return result;
};

export const findSurveyResponsesBySurveyIdAndParticipantId = async (
  surveyId,
  participantId,
) => {
  var result = undefined;

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var creation_timestamp = data.creation_timestamp.toDate();
    var surveyResponse = {
      ...data,
      id: doc.id,
      creation_timestamp,
    };

    if (
      surveyResponse.survey_id === surveyId &&
      surveyResponse.participant_id === participantId
    ) {
      result = surveyResponse;
    }
  });

  return result;
};

export const findSurveyResponsesBySurveyId = async (surveyId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var creation_timestamp = data.creation_timestamp.toDate();
    var surveyResponse = {
      ...data,
      id: doc.id,
      creation_timestamp,
    };

    if (surveyResponse.survey_id === surveyId) {
      result.push(surveyResponse);
    }
  });

  return result;
};

export const addSurveyResponse = async (surveyResponse) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), surveyResponse);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

export const updateSurveyResponseById = async (surveyResponse) => {
  var result = undefined;

  var newSurveyResponseToSave = { ...surveyResponse };
  delete newSurveyResponseToSave.id;
  const docRef = doc(db, collectionName, surveyResponse.id);

  await updateDoc(docRef, newSurveyResponseToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

export const deleteSurveyResponseById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};
