import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'job_experiences';

const JobExperiences = {};

JobExperiences.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

JobExperiences.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    var id = docSnap.id;

    result = { ...data, id };
  }

  return result;
};

JobExperiences.findByJobId = async (jobId) => {
  var result = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var id = doc.id;
    var participantExperience = { ...data, id };

    if (participantExperience.job_id === jobId) {
      result.push(participantExperience);
    }
  });

  return result;
};

JobExperiences.add = async (participantExperience) => {
  var result = undefined;

  const docRef = await addDoc(
    collection(db, collectionName),
    participantExperience,
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

JobExperiences.update = async (participantExperience) => {
  var result = undefined;

  var newJobExperienceToSave = { ...participantExperience };
  delete newJobExperienceToSave.id;
  const docRef = doc(db, collectionName, participantExperience.id);

  await updateDoc(docRef, newJobExperienceToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

JobExperiences.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default JobExperiences;
