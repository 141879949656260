import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './init';

const collectionName = 'coaches';

const Coaches = {};

Coaches.getAll = async () => {
  const q = query(collection(db, 'users'));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

/* Supervisors.findWithUserObj = async (user) => {
  var result = await Supervisors.findByEmail(user.email);

  if (!result) {
    return undefined;
  }

  var obj = {
    ...user,
    ...result,
  };

  return obj;
}; */

Coaches.findById = async (id) => {
  var result = undefined;

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var supervisor = { ...data, id: doc.id };

    if (doc.id === id) {
      result = supervisor;
      result.type = 'supervisor';
    }
  });

  return result;
};

Coaches.findByEmail = async (email) => {
  var result = undefined;

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var user = { ...data, id: doc.id };

    if (user.email === email) {
      result = user;
      result.type = 'supervisor';
    }
  });

  return result;
};

Coaches.findByCompanyId = async (id) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', id),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var user = { ...data, id: doc.id, type: 'supervisor' };
    result.push(user);
  });

  return result;
};

Coaches.findByOfficeId = async (officeId) => {
  var result = [];

  const q = query(collection(db, 'access_to_office'));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    var data = doc.data();
    var access = { ...data, id: doc.id };

    if (access.office_id === officeId) {
      var supervisor = await Coaches.findById(access.id);
      result.push(supervisor);
    }
  });

  return result;
};

export default Coaches;
