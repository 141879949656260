import React from 'react';
import CoachRoutes from './CoachRoutes';
import AdminRoutes from './AdminRoutes';
import PublicRoutes from './PublicRoutes';
import ParticipantRoutes from './ParticipantRoutes';
import AreaManagerRoutes from './AreaManagerRoutes';

const Routes = ({ user }) => {
  if (user?.type === 'admin') return <AdminRoutes />;
  if (user?.type === 'area_manager') return <AreaManagerRoutes />;
  if (user?.type === 'coach') return <CoachRoutes />;
  if (user?.type === 'participant') return <ParticipantRoutes />;
  return <PublicRoutes />;
};

export default Routes;
