import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'delivery_area';

const DeliveryAreas = {};

DeliveryAreas.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

DeliveryAreas.findByCompanyId = async (companyId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('company_id', '==', companyId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var deliveryArea = { ...data, id: doc.id };
    result.push(deliveryArea);
  });

  return result;
};

DeliveryAreas.findById = async (id) => {
  var result = undefined;
  const docRef = doc(db, collectionName, id);
  const querySnapshot = await getDoc(docRef);
  if (querySnapshot.exists()) {
    var data = querySnapshot.data();
    result = { ...data, id: querySnapshot.id };
  }
  return result;
};

DeliveryAreas.add = async (deliveryArea) => {
  var result = undefined;

  const docRef = await addDoc(collection(db, collectionName), deliveryArea);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

DeliveryAreas.updateById = async (id, deliveryArea) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, deliveryArea);

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

DeliveryAreas.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default DeliveryAreas;
